<template>
  <div>
    <list-filter
      v-if="!hideFullFilter"
      v-model="filters"
      :hide-filters="hideFilters"
    />

    <common-trs-card
      v-if="details.length > 0"
      class="trs-table-container-card"
    >
      <common-trs-data-table
        :headers="headers"
        :items="details"
        :loading="loading"
        disable-pagination
        :hide-default-footer="true"
        :disable-custom-footer="true"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="showField('first_name')">
              <span>{{ item.first_name }}</span>
            </td>
            <td v-if="showField('last_name')">
              <span>{{ item.last_name }}</span>
            </td>
            <td v-if="showField('org_name')">
              <span>{{ item.org_name }}</span>
            </td>
            <td v-if="showField('created_on')">
              {{ item.created_on ? $date(item.created_on) : '-' }}
            </td>
            <td v-if="showField('modified_on')">
              {{ item.modified_on ? $date(item.modified_on) : '-' }}
            </td>
            <td v-if="showField('org_name')">
              <span>{{ CONSTS.INVITE_STATUS_TEXT[item.invite_status] }}</span>
            </td>
            <td v-if="showField('actions')" />
          </tr>
        </template>
      </common-trs-data-table>
    </common-trs-card>
    <div
      v-else
      class="mt-2"
    >
      <empty-data-placeholder
        :placeholder-text="placeholderText"
      />
    </div>
    <common-trs-data-table-list-pager
      v-model="pager"
    />
  </div>
</template>

<script>
  import ListFilter from './ListFilter'
  import * as captableAdminService from '@/services/tApp-admin-users'
  import EmptyDataPlaceholder from '@/components/common/components/EmptyDataPlaceholder'
  import debounce from 'lodash.debounce'

  const TABLE_HEADERS = [
    { sortable: true, text: 'First Name', value: 'first_name' },
    { sortable: true, text: 'Last Name', value: 'last_name' },
    { sortable: true, text: 'Organization', value: 'org_name' },
    { sortable: false, text: 'Onboarded Date', value: 'created_on' },
    { sortable: true, text: 'Last Logged On', value: 'modified_on' },
    { sortable: true, text: 'Status', value: 'invite_status' },
    { sortable: false, text: 'Actions', value: 'actions', width: 150 },
  ]
  export default {
    name: 'CaptableUsersList',

    components: {
      ListFilter,
      EmptyDataPlaceholder,
    },

    props: {
      preFilters: {
        type: Object,
        default: () => {},
      },
      hideFilters: {
        validate: () => true,
        default: () => [],
      },
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      defaultHideFields: {
        validate: () => true,
        default: () => [],
      },
      hideFullFilter: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      filters: {},
      totalCount: 0,
      pager: {
        itemsPerPage: 10,
        page: 1,
        total: 1,
      },
      loading: false,
      details: [],
    }),

    computed: {
      allFilters () {
        return {
          filters: this.filters,
          pager: this.pager,
          sort: this.sort,
        }
      },
      placeholderText () {
        let text = ''
        if (this.filters.first_name || this.filters.last_name) {
          text = 'No search results found'
        } else {
          text = 'You currently do not have any users'
        }
        return text
      },
      headers () {
        const val = this.hideFields.concat(this.defaultHideFields)
        return TABLE_HEADERS.filter(header => val.indexOf(header.value) === -1)
      },
    },

    watch: {
      preFilters: {
        handler () {
          this.filters = {
            ...this.filters,
            ...this.preFilters,
          }
        },
        deep: true,
        immediate: true,
      },
      pager: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
      filters: {
        handler: debounce(function (val) {
          this.pager.page = 1
          this.loadData()
        }, 500),
        deep: true,
      },
    },

    mounted () {
      this.loadData()
    },

    methods: {
      showField (fieldName) {
        return !!this.headers.find(header => header.value === fieldName)
      },
      async loadData () {
        this.loading = true
        try {
          const response = await captableAdminService.getCaptableUsers({
            filters: this.filters,
            page: this.pager.page,
            itemsPerPage: this.pager.itemsPerPage,
          })
          this.details = response.results
          this.pager.total = Math.ceil(response.count / this.pager.itemsPerPage) || 1
        } catch (error) {
          this.$store.dispatch('app/handleError', {
            error,
            message: 'Failed to load Cap Table Users',
          })
        }
        this.loading = false
      },
    },
  }
</script>
<style lang="scss">
  .status-filter-container {
    background: #f2f2f2;
  }
</style>
