<template>
  <common-trs-card
    elevation="0"
    class="d-flex flex-column justify-center align-center pa-8"
  >
    <common-trs-logos-finger-print-icon
      type="7"
      width="73"
    />
    <h2 class="trsText--text text-h5 mb-1">
      {{ placeholderText }}
    </h2>
    <common-trs-btn
      v-if="btnText.length > 0"
      large
      depressed
      type="primary"
      class="white--text mb-2 mt-4"
      :to="navigateTo"
      :pem-roles-allowed="pemRolesAllowed"
    >
      {{ btnText }}
    </common-trs-btn>
  </common-trs-card>
</template>

<script>
  export default {
    name: 'EmptyDataPlaceholder',
    props: {
      placeholderText: {
        type: String,
        default: '',
      },
      btnText: {
        type: String,
        default: '',
      },
      navigateTo: {
        type: Object,
        default: () => {},
      },
      pemRolesAllowed: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      portal () {
        return this.$getPortal()
      },
    },
  }
</script>
