<template>
  <div class="pa-0">
    <common-trs-page-header title="Cap Table Users" />
    <common-trs-filter-card
      class="mb-2 trs-filter-input"
      :applied-filter="$getAppliedFilter(internalValue, availableKeys, [])"
    >
      <template v-slot:primary-filter="{ toggleExpanded, expanded }">
        <common-input-text-field
          v-model="internalValue.first_name"
          label="Search by Name"
          color="primary"
          clearable
          hide-details
          single-line
          prepend-inner-icon="mdi-magnify"
        >
          <template v-slot:append>
            <common-trs-filter-card-expand-btn
              :expanded="expanded"
              @toggleExpanded="toggleExpanded"
            />
          </template>
        </common-input-text-field>
      </template>
      <template v-slot:actions>
        <div class="d-flex justify-space-between align-center">
          <common-trs-btn
            text
            large
            type="primary"
            class="white--text"
            :to="{ name: 'Users.Create' }"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            New User
          </common-trs-btn>
        </div>
      </template>
      <template v-slot:content>
        <v-col
          v-if="hideFilters.indexOf('last_name') === -1"
          cols="12"
          sm="4"
        >
          <common-input-text-field
            v-model="internalValue.last_name"
            label="Last Name"
            color="primary"
            clearable
            hide-details
          />
        </v-col>
        <v-col
          v-if="hideFilters.indexOf('org_name') === -1"
          cols="12"
          sm="4"
        >
          <common-input-text-field
            v-model="internalValue.org_name"
            label="Organization Name"
            color="primary"
            clearable
            hide-details
          />
        </v-col>
        <v-col
          v-if="hideFilters.indexOf('invite_status') === -1"
          cols="12"
          sm="4"
        >
          <common-input-select
            v-model="internalValue.invite_status"
            label="Invite Status"
            placeholder="Invite Status"
            :items="CONSTS.INVITE_STATUS_TYPES"
            hide-details
          />
        </v-col>
      </template>
      <template v-slot:append>
        <slot />
      </template>
    </common-trs-filter-card>
  </div>
</template>

<script>
  export default {
    name: 'ListFilter',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
      hideFilters: {
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      cPanel: 0,
      availableKeys: [
        { key: 'first_name' },
        { key: 'last_name' },
        { key: 'org_name' },
        { key: 'invite_status' },
      ],
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    methods: {
    },
  }
</script>
