<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <users-list
        :pre-filters="{}"
        :hide-filters="[]"
        :hide-fields="['actions']"
        :default-hide-fields="[]"
      />
    </template>
  </common-trs-page-container>
</template>
<script>
  import UsersList from './components/List'
  export default {
    name: 'CaptableUsers',
    components: {
      UsersList,
    },
  }
</script>
